import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  lighten
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useGetCurrBillQuery } from 'src/redux/services/acompanyApi';
import { resetState } from 'src/redux/services/authSlice';
import { RootState } from 'src/redux/store';
import notification from '../../../../components/Notification';
import { useConfirmation } from '../../../../components/Dialog/ConfirmationService';
import { useInsertPaymentMutation } from 'src/redux/services/apaymentApi';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const confirm = useConfirmation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gdata = useSelector((state: RootState) => state.gdata);
  const user = {
    name: gdata.username,
    avatar: '/static/images/avatars/1.jpg',
    jobtitle: 'Admin'
  };

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const [insertPayment] = useInsertPaymentMutation();
  const { data } = useGetCurrBillQuery(gdata.company);

  useEffect(() => {
    if (data?.length > 0) {
      notification.warning({
        title: 'Error',
        message: `Please Pay the Bill Amount Rs. ${
          Number(data[0]?.bl_net) == 0
            ? Number(data[0]?.bl_netamt1)
            : Number(data[0]?.bl_net)
        } Balance Amount Rs. ${
          Number(data[0]?.bl_net) == 0
            ? Number(data[0]?.bl_netamt1)
            : Number(data[0]?.bl_net) - Number(data[0]?.bl_received)
        }  Pay immediately to ensure uninterrupted service?...`
      });
      // confirm({
      //   variant: 'danger',
      //   catchOnCancel: true,
      //   title: 'BlueHMS',
      //   description: `Please Pay the Bill <br><table width='100%'><tr><td width='70%'>Bill Amount</td><td width='30%' align='right'>Rs. ${
      //     Number(data[0]?.bl_net) == 0
      //       ? Number(data[0]?.bl_netamt1)
      //       : Number(data[0]?.bl_net)
      //   } </td></tr><tr><td>Balance Amount</td><td width='30%' align='right'>Rs. <b>${
      //     Number(data[0]?.bl_net) == 0
      //       ? Number(data[0]?.bl_netamt1)
      //       : Number(data[0]?.bl_net) - Number(data[0]?.bl_received)
      //   } </b></td></tr></table><br>Pay immediately to ensure uninterrupted service?...`
      // })
      //   .then(async () => {
      //     const result = await insertPayment({
      //       user_id: gdata.phone,
      //       price:
      //         Number(data[0]?.bl_net) == 0
      //           ? Number(data[0]?.bl_netamt1)
      //           : Number(data[0]?.bl_net) - Number(data[0]?.bl_received),
      //       phone: gdata.phone,
      //       name: gdata.companyname
      //     });
      //     console.log('sucess', result);
      //   })
      //   .catch(() => {
      //     console.log('error');
      //   });
    }
  }, [data]);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(resetState());

    navigate('/');
  };

  const handleChangePwd = () => {
    navigate('/management/profile/changepassword');

    setOpen(false);
  };

  const handleProfile = () => {
    navigate('/management/profile');

    setOpen(false);
  };

  const handleBill = () => {
    navigate('/status/invoice');

    setOpen(false);
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={user.name} src={user.avatar} />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            {data?.length > 0 ? (
              <UserBoxDescription variant="body2">
                {data ? `Rs.${data[0]?.bl_netamt}/mon` : ''}
              </UserBoxDescription>
            ) : null}
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={user.name} src={user.avatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            {data?.length > 0 ? (
              <UserBoxDescription variant="body2">
                {data && `Current Bill : ₹. ${data[0]?.bl_netamt1}`}
              </UserBoxDescription>
            ) : null}
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem button onClick={handleChangePwd}>
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary="Change Password" />
          </ListItem>
          <ListItem button onClick={handleProfile}>
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary="Profile" />
          </ListItem>
          {data?.length > 0 ? (
            <ListItem button onClick={handleBill}>
              <AccountTreeTwoToneIcon fontSize="small" />
              <ListItemText
                primary={
                  data && `View Current Bill : ₹. ${data[0]?.bl_netamt1}`
                }
              />
            </ListItem>
          ) : null}
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
