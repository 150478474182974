import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';

function BTComboBox(props: any) {
  const { onValChange, onKeyDown } = props;
  const { field, fieldState } = useController(props);
  const { register } = useForm();
  const [inputValue, setInputValue] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    register(field.name);
  }, [register, field.name]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // Check if the Enter key was pressed and the dropdown is not open
    if (event.key === 'Enter' && !isDropdownOpen) {
      event.preventDefault(); // Prevent the default action of the Enter key

      // Use a more specific selector to exclude elements within the Autocomplete dropdown
      const focusableElements =
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
      const focusables: NodeListOf<HTMLElement> =
        document.querySelectorAll(focusableElements);

      // Convert NodeList to Array and filter out elements that are not visible or within the dropdown
      const visibleFocusables = Array.from(focusables).filter(
        (el) =>
          el.offsetWidth > 0 &&
          el.offsetHeight > 0 &&
          !el.closest('.MuiAutocomplete-popper') // Exclude elements within the Autocomplete dropdown
      );

      const currentIndex = visibleFocusables.indexOf(
        document.activeElement as HTMLElement
      );
      const nextIndex = currentIndex + 1;

      // If there's a next focusable element, move focus to it
      if (nextIndex >= 0 && nextIndex < visibleFocusables.length) {
        visibleFocusables[nextIndex].focus();
      }
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  return (
    <Autocomplete
      id={props.name}
      options={props.data ? props.data : []}
      onOpen={() => setIsDropdownOpen(true)}
      onClose={() => setIsDropdownOpen(false)}
      ref={field.ref}
      onChange={(e, newValue) => {
        // Check if multiple is true and handle accordingly
        const newValueId = props.multiple
          ? newValue
            ? newValue?.map((item: any) => item.id)
            : [] // Ensure newValue is checked before mapping
          : newValue
          ? newValue.id
          : ''; // Single selection handling

        field.onChange(newValueId); // Update form field value

        if (onValChange) onValChange(newValue); // If an external change handler is provided, call it
      }}
      value={
        props.multiple
          ? field.value
            ? props.data?.filter((option: any) =>
                field.value.includes(option.id)
              )
            : [] // Ensure field.value is checked before calling includes
          : props.data?.find((option: any) => option.id === field.value) || null // Handle single selection
      }
      onBlur={field.onBlur}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue ? newInputValue : '');
      }}
      getOptionLabel={(option) => (option ? option.label : '')}
      isOptionEqualToValue={(option, newValue) => option?.id === newValue?.id}
      multiple={props.multiple ? props.multiple : false}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label={props.label}
          error={Boolean(fieldState.error)}
          helperText={fieldState.error?.message}
          autoFocus={props.autoFocus}
          onKeyDown={handleKeyDown}
        />
      )}
      style={{ width: '100%', color: 'black' }}
      disabled={props.disabled}
    />
  );
}

export default BTComboBox;
