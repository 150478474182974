import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { usersApi } from './ausersApi';

export interface AuthState {
  user: string;
  username: string;
  token: string;
  company: string;
  companyname: string;
  addr1: string;
  addr2: string;
  city: string;
  state: string;
  pincode: string;
  phone: string;
  email: string;
  web: string;
  logo: string;
  today: string;
  tax1no: string;
  tax2no: string;
  tax3no: string;
  defsource: number;
  defplan: number;
  defmode: number;
  defidtype: number;
  defpurpose: number;
  defstate: number;
  defcountry: number;
  vacant: string;
  occupied: string;
  group: string;
  houseguest: string;
  expcheckout: string;
  dirty: string;
  block: string;
  maintenance: string;
  menu?: (bt_amenu & bt_ausermenu & bt_amodules)[];
  invoiceTemplate: number;
  invoiceCopies: number;
  grc: number;
  acVocTemplate: number;
  outlet: number;
  checkin: number;
  table: number;
  emottype: Number;
  emwageday: Number;
  generalinfo: string;
  facilities: string;
}

const initialState: AuthState = {
  user: '',
  username: '',
  token: '',
  company: '',
  companyname: '',
  addr1: '',
  addr2: '',
  city: '',
  state: '',
  pincode: '',
  phone: '',
  email: '',
  web: '',
  logo: '',
  today: '',
  tax1no: '',
  tax2no: '',
  tax3no: '',
  defsource: 0,
  defplan: 0,
  defmode: 0,
  defidtype: 0,
  defpurpose: 0,
  defstate: 0,
  defcountry: 0,
  vacant: '',
  occupied: '',
  group: '',
  houseguest: '',
  expcheckout: '',
  dirty: '',
  block: '',
  maintenance: '',
  invoiceTemplate: 0,
  invoiceCopies: 1,
  grc: 1,
  acVocTemplate: 0,
  outlet: 0,
  checkin: 0,
  table: 0,
  emottype: 1,
  emwageday: 7,
  generalinfo: '',
  facilities: ''
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      usersApi.endpoints.signIn.matchFulfilled,
      (state, { payload }) => {
        state.user = payload.us_id;
        state.username = payload.us_name;
        state.token = payload.us_token;
        state.company = payload.cm_id;
        state.companyname = payload.cm_name;
        state.addr1 = payload.cm_addr1;
        state.addr2 = payload.cm_addr2;
        state.city = payload.cm_city;
        state.state = payload.cm_state;
        state.pincode = payload.cm_pincode;
        state.phone = payload.cm_phone;
        state.email = payload.cm_email;
        state.web = payload.cm_web;
        state.logo = payload.cm_logo;
        state.today = payload.se_date;
        state.tax1no = payload.se_tax1no;
        state.tax2no = payload.se_tax2no;
        state.tax3no = payload.se_tax3no;
        state.defsource = payload.se_defsource;
        state.defplan = payload.se_defplan;
        state.defmode = payload.se_defmode;
        state.defidtype = payload.se_defidtype;
        state.defpurpose = payload.se_defpurpose;
        state.defstate = payload.se_state;
        state.defcountry = payload.se_country;
        state.vacant = payload.se_vacant || '#255F19';
        state.occupied = payload.se_occupied || '#B51A2A';
        state.group = payload.se_group || '#6571D6';
        state.houseguest = payload.se_houseguest || '#26B8A5';
        state.expcheckout = payload.se_expco || '#E01CB3';
        state.dirty = payload.se_dirty || '#797478';
        state.block = payload.se_block || '#A04229';
        state.maintenance = payload.se_maintenance || '#B5620F';
        state.menu = payload.us_menu;
        state.grc = payload.se_grc;
        state.invoiceTemplate = payload.se_billformat;
        state.invoiceCopies = payload.se_copies;
        state.acVocTemplate = payload.se_voctemp;
        state.emottype = payload.es_ottype;
        state.emwageday = payload.es_wagesday;
      }
    );
    builder.addMatcher(
      usersApi.endpoints.guestSignIn.matchFulfilled,
      (state, { payload }) => {
        state.user = payload.gc_id;
        state.username = payload.gc_mobile;
        state.token = payload.gc_token;
        state.company = payload.cm_id;
        state.companyname = payload.cm_name;
        state.addr1 = payload.cm_addr1;
        state.addr2 = payload.cm_addr2;
        state.city = payload.cm_city;
        state.state = payload.cm_state;
        state.pincode = payload.cm_pincode;
        state.phone = payload.cm_phone;
        state.email = payload.cm_email;
        state.web = payload.cm_web;
        state.logo = payload.cm_logo;
        state.outlet = payload.gc_outlet;
        state.checkin = payload.gc_checkin;
        state.table = payload.gc_table;
      }
    );
    builder.addMatcher(
      usersApi.endpoints.roomSignIn.matchFulfilled,
      (state, { payload }) => {
        state.user = payload.gc_id;
        state.username = payload.gc_mobile;
        state.token = payload.gc_token;
        state.company = payload.cm_id;
        state.companyname = payload.cm_name;
        state.addr1 = payload.cm_addr1;
        state.addr2 = payload.cm_addr2;
        state.city = payload.cm_city;
        state.state = payload.cm_state;
        state.pincode = payload.cm_pincode;
        state.phone = payload.cm_phone;
        state.email = payload.cm_email;
        state.web = payload.cm_web;
        state.logo = payload.cm_logo;
        state.outlet = payload.gc_outlet;
        state.checkin = payload.ci_id;
        state.table = payload.rm_id;
        state.generalinfo = payload.cm_general;
        state.facilities = payload.cm_facilities;
      }
    );
  }
});

export default authSlice.reducer;
export const { resetState } = authSlice.actions;

export const selectCurrentUser = (state: RootState) => state.gdata;
