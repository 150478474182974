import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import * as React from 'react';

export interface ConfirmationOptions {
  catchOnCancel?: boolean;
  variant?: 'danger' | 'info';
  title?: string;
  description?: string;
  htmlcomp?: any;
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

const useStyles = makeStyles({
  dialogTitle: {
    fontWeight: 'bold',
    fontSize: '2rem',
    color: '#fff'
  },
  danger: {
    backgroundColor: '#ff1744' // Vibrant red color
  },
  info: {
    backgroundColor: '#00bfa5' // Cool teal color
  }
});

const StyledDivider = styled(Divider)({
  margin: '6px 0'
});

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  variant,
  description,
  htmlcomp,
  onSubmit,
  onClose
}) => {
  const classes = useStyles();
  const titleClass = `${classes.dialogTitle} ${
    variant === 'danger' ? classes.danger : classes.info
  }`;

  return (
    <Dialog open={open}>
      <DialogTitle id="alert-dialog-title" className={titleClass}>
        {title}
      </DialogTitle>
      <DialogContent>
        {description ? (
          <DialogContentText component="div">
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </DialogContentText>
        ) : (
          htmlcomp
        )}
      </DialogContent>
      <StyledDivider />
      <DialogActions>
        {variant === 'danger' && (
          <>
            <Button variant="contained" onClick={onSubmit}>
              Yes, I agree
            </Button>
            <Button color="primary" onClick={onClose} autoFocus>
              CANCEL
            </Button>
          </>
        )}

        {variant === 'info' && (
          <Button color="primary" onClick={onSubmit}>
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
