import {createApi} from '@reduxjs/toolkit/query/react';
import {btBaseQuery} from '../../Common/common';

// Define a service using a base URL and expected endpoints
export const pomproductblockApi = createApi({
  reducerPath: 'pomproductblockApi',
  baseQuery: btBaseQuery,
  tagTypes: ['POMProductBlock'],

  endpoints: builder => ({
    getPOMProductBlocks: builder.query<po_mkitchen[], string>({
      query: cid => `pomproductblock/${cid}`,
      providesTags: ['POMProductBlock'],
    }),
    getPOMProductBlockById: builder.query<po_mkitchen, string>({
      query: id => `pomproductblock/byid/${id}`,
      providesTags: ['POMProductBlock'],
    }),
    insertPOMProductBlock: builder.mutation({
      query: pomproductblock => ({
        url: `pomproductblock/`,
        method: 'POST',
        body: pomproductblock,
      }),
      invalidatesTags: ['POMProductBlock'],
    }),
    updatePOMProductBlock: builder.mutation({
      query: pomproductblock => ({
        url: `pomproductblock/`,
        method: 'PUT',
        body: pomproductblock,
      }),
      invalidatesTags: ['POMProductBlock'],
    }),
    deletePOMProductBlock: builder.mutation({
      query: pomproductblock => ({
        url: `pomproductblock/${pomproductblock.kt_id}`,
        method: 'DELETE',
        body: pomproductblock,
      }),
      invalidatesTags: ['POMProductBlock'],
    }),
  }),
});

export const {
  useGetPOMProductBlocksQuery,
  useGetPOMProductBlockByIdQuery,
  useInsertPOMProductBlockMutation,
  useUpdatePOMProductBlockMutation,
  useDeletePOMProductBlockMutation,
} = pomproductblockApi;
