import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';
import BaseLayout from 'src/layouts/BaseLayout';
import HomeLayout from 'src/layouts/HomeLayout';
import SidebarLayout from 'src/layouts/SidebarLayout';
import GuestLayout from './layouts/GuestLayout';

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Home = Loader(lazy(() => import('src/screens/management/Home')));

const AdminCompany = Loader(
  lazy(() => import('src/screens/pages/Admin/Company'))
);
const Systems = Loader(lazy(() => import('src/screens/pages/Admin/Systems')));
const AdminUsers = Loader(lazy(() => import('src/screens/pages/Admin/Users')));
const UserMenu = Loader(lazy(() => import('src/screens/pages/Admin/UserMenu')));
const Forget = Loader(lazy(() => import('src/screens/auth/Forget')));

const Invoice = Loader(lazy(() => import('src/screens/pages/Admin/Invoice')));

const AdminCountry = Loader(
  lazy(() => import('src/screens/pages/Admin/Country'))
);
const AdminState = Loader(lazy(() => import('src/screens/pages/Admin/State')));
const AdminModes = Loader(lazy(() => import('src/screens/pages/Admin/Mode')));
const AdminTaxGroup = Loader(
  lazy(() => import('src/screens/pages/Admin/TaxGroup'))
);
const AdminTax = Loader(lazy(() => import('src/screens/pages/Admin/Tax')));
const AdminUom = Loader(lazy(() => import('src/screens/pages/Admin/Uom')));

const AdminFOSettings = Loader(
  lazy(() => import('src/screens/pages/Admin/FOSettings'))
);
const ACSettings = Loader(
  lazy(() => import('src/screens/pages/Admin/ACSettings'))
);
const SMSettings = Loader(
  lazy(() => import('src/screens/pages/Admin/SMSettings'))
);

const AdminSTSettings = Loader(
  lazy(() => import('src/screens/pages/Admin/STSettings'))
);
const MastersSupplier = Loader(
  lazy(() => import('src/screens/pages/Masters/Supplier'))
);
const STGroup = Loader(lazy(() => import('src/screens/pages/Masters/STGroup')));
const STProduct = Loader(
  lazy(() => import('src/screens/pages/Masters/STProduct'))
);

const MastersEmployee = Loader(
  lazy(() => import('src/screens/pages/Masters/Employee'))
);
const Department = Loader(
  lazy(() => import('src/screens/pages/Masters/Department'))
);

const ACMHead = Loader(lazy(() => import('src/screens/pages/Masters/ACHead')));
const ACMCompany = Loader(
  lazy(() => import('src/screens/pages/Masters/Company'))
);

const ACEExpenses = Loader(
  lazy(() => import('src/screens/pages/Transaction/ACExpenses'))
);
const ACEIncomes = Loader(
  lazy(() => import('src/screens/pages/Transaction/ACIncomes'))
);
const ACPayment = Loader(
  lazy(() => import('src/screens/pages/Transaction/ACPayment'))
);
const ACReceipt = Loader(
  lazy(() => import('src/screens/pages/Transaction/ACReceipt'))
);

const ReportsExpenses = Loader(
  lazy(() => import('src/screens/pages/Reports/ACExpenses'))
);
const RepExpensesSumm = Loader(
  lazy(() => import('src/screens/pages/Reports/ACExpensesSumm'))
);
const RepExpensesCol = Loader(
  lazy(() => import('src/screens/pages/Reports/ACExpensesCol'))
);
const RepExpensesDetails = Loader(
  lazy(() => import('src/screens/pages/Reports/ACExpensesDetails'))
);
const ReportsIncomes = Loader(
  lazy(() => import('src/screens/pages/Reports/ACIncomes'))
);
const ACOutstanding = Loader(
  lazy(() => import('src/screens/pages/Reports/ACOutstanding'))
);
const ACSuppOutstanding = Loader(
  lazy(() => import('src/screens/pages/Reports/ACSuppOutstanding'))
);
const ACSuppOutstDetails = Loader(
  lazy(() => import('src/screens/pages/Reports/ACSuppOutstDetails'))
);
const ACProfitAndLoss = Loader(
  lazy(() => import('src/screens/pages/Reports/ACProfitAndLoss'))
);
const ACPaymentReceipts = Loader(
  lazy(() => import('src/screens/pages/Reports/ACPaymentReceipts'))
);

const MasterFORoomType = Loader(
  lazy(() => import('src/screens/pages/Masters/FORoomType'))
);
const MasterFORoomTariff = Loader(
  lazy(() => import('src/screens/pages/Masters/FORoomTariff'))
);
const MasterFORooms = Loader(
  lazy(() => import('src/screens/pages/Masters/FORooms'))
);
const FOMPlans = Loader(
  lazy(() => import('src/screens/pages/Masters/FOPlans'))
);
const FOMSource = Loader(
  lazy(() => import('src/screens/pages/Masters/FOSource'))
);
const FOMIDType = Loader(
  lazy(() => import('src/screens/pages/Masters/FOIDType'))
);
const FOMPurpose = Loader(
  lazy(() => import('src/screens/pages/Masters/FOPurpose'))
);
const FOMHead = Loader(lazy(() => import('src/screens/pages/Masters/FOHead')));
const AddrBook = Loader(
  lazy(() => import('src/screens/pages/Masters/AddressBook'))
);

const FOEReservation = Loader(
  lazy(() => import('src/screens/pages/Transaction/FOReservation'))
);
const FORReservation1 = Loader(
  lazy(() => import('src/screens/pages/Transaction/FOReservation1'))
);
const FOEReserAdv = Loader(
  lazy(() => import('src/screens/pages/Transaction/FOReserAdv'))
);
const FOEReserCancel = Loader(
  lazy(() => import('src/screens/pages/Transaction/FOReserCancel'))
);
const FOECheckin = Loader(
  lazy(() => import('src/screens/pages/Transaction/FOCheckin'))
);
const FOECheckinAdv = Loader(
  lazy(() => import('src/screens/pages/Transaction/FOAdvance'))
);
const FOEPostings = Loader(
  lazy(() => import('src/screens/pages/Transaction/FOPostings'))
);
const FOECheckout = Loader(
  lazy(() => import('src/screens/pages/Transaction/FOCheckout'))
);
const FOERoomShift = Loader(
  lazy(() => import('src/screens/pages/Transaction/FORoomShift'))
);
const FOEPaxAlter = Loader(
  lazy(() => import('src/screens/pages/Transaction/FOPaxAlter'))
);
const FOEHouseKeeping = Loader(
  lazy(() => import('src/screens/pages/Transaction/FOHouseKeeping'))
);
const FOLinkRooms = Loader(
  lazy(() => import('src/screens/pages/Transaction/FOLinkRooms'))
);
const FORReservation = Loader(
  lazy(() => import('src/screens/pages/Reports/FOReservation'))
);
const FOROccChart = Loader(
  lazy(() => import('src/screens/pages/Reports/FOOccChart'))
);
const FOPolice = Loader(
  lazy(() => import('src/screens/pages/Reports/FOPolice'))
);
const FORCashier = Loader(
  lazy(() => import('src/screens/pages/Reports/FOCashier'))
);
const FOCheckinAC = Loader(
  lazy(() => import('src/screens/pages/Reports/FOCheckinAC'))
);
const FOCheckoutAC = Loader(
  lazy(() => import('src/screens/pages/Reports/FOCheckoutAC'))
);
const FORevenueSummary = Loader(
  lazy(() => import('src/screens/pages/Reports/FORevenueSummary'))
);
const FODFRCheckout = Loader(
  lazy(() => import('src/screens/pages/Reports/FODFRCheckout'))
);
const FOTax = Loader(lazy(() => import('src/screens/pages/Reports/FOTax')));
const FOMonthly = Loader(
  lazy(() => import('src/screens/pages/Reports/FOMonthly'))
);
const FOCharges = Loader(
  lazy(() => import('src/screens/pages/Reports/FOCharges'))
);
const FODayBook = Loader(
  lazy(() => import('src/screens/pages/Reports/FODayBook'))
);
const FOOTASales = Loader(
  lazy(() => import('src/screens/pages/Reports/FOOTASales'))
);
const FOOutstanding = Loader(
  lazy(() => import('src/screens/pages/Reports/FOOutstanding'))
);
const FOTourism = Loader(
  lazy(() => import('src/screens/pages/Reports/FOTourism'))
);
const FORatePost = Loader(
  lazy(() => import('src/screens/pages/Reports/FORatePost'))
);
const FOFoodCoupon = Loader(
  lazy(() => import('src/screens/pages/Reports/FOFoodCoupon'))
);
const FOReceipts = Loader(
  lazy(() => import('src/screens/pages/Reports/FOReceipts'))
);
const FOPrintTrans = Loader(
  lazy(() => import('src/screens/pages/Reports/FOPrintTrans'))
);
const FOPrintCITrans = Loader(
  lazy(() => import('src/screens/pages/Reports/FOPrintCITrans'))
);
const FOLog = Loader(lazy(() => import('src/screens/pages/Reports/FOLog')));

const AdminPOOutlet = Loader(
  lazy(() => import('src/screens/pages/Admin/POOutlet'))
);
const MastersPOTax = Loader(
  lazy(() => import('src/screens/pages/Masters/POTax'))
);
const MastersPOGroup = Loader(
  lazy(() => import('src/screens/pages/Masters/POGroup'))
);
const MastersPOProduct = Loader(
  lazy(() => import('src/screens/pages/Masters/POProduct'))
);
const POSProductBlock = Loader(
  lazy(() => import('src/screens/pages/Masters/POSProductBlock'))
);
const MastersPOTable = Loader(
  lazy(() => import('src/screens/pages/Masters/POTable'))
);
const POSReceipe = Loader(
  lazy(() => import('src/screens/pages/Masters/POSReceipe'))
);

const MTMJobType = Loader(
  lazy(() => import('src/screens/pages/Masters/JobType'))
);
const MTMItemType = Loader(
  lazy(() => import('src/screens/pages/Masters/ItemType'))
);

const POSKOTTableList = Loader(
  lazy(() => import('src/screens/pages/Transaction/POSTables'))
);
const POSKOTEntry = Loader(
  lazy(() => import('src/screens/pages/Transaction/POSKOTEntry'))
);
const POSKOTNCEntry = Loader(
  lazy(() => import('src/screens/pages/Transaction/POSKOTNCEntry'))
);
const POSKOTCancel = Loader(
  lazy(() => import('src/screens/pages/Transaction/POSKOTCancel'))
);
const POSRunningOrders = Loader(
  lazy(() => import('src/screens/pages/Transaction/POSRunningOrders'))
);
const POSSettle = Loader(
  lazy(() => import('src/screens/pages/Transaction/POSSettle'))
);
const POSCashClose = Loader(
  lazy(() => import('src/screens/pages/Transaction/POSCashClose'))
);
const POSIssue = Loader(
  lazy(() => import('src/screens/pages/Transaction/POSIssueEntry'))
);
const POListBills = Loader(
  lazy(() => import('src/screens/pages/Reports/POListBills'))
);
const POListOrders = Loader(
  lazy(() => import('src/screens/pages/Reports/POListOrders'))
);
const POReports = Loader(
  lazy(() => import('src/screens/pages/Reports/POCashier'))
);
const ReportsPOWaiterWise = Loader(
  lazy(() => import('src/screens/pages/Reports/POWaiterWise'))
);
const ReportsPOItemWise = Loader(
  lazy(() => import('src/screens/pages/Reports/POItemWise'))
);
const ReportsPOOrder = Loader(
  lazy(() => import('src/screens/pages/Reports/POOrder'))
);
const ReportsPOOrderNC = Loader(
  lazy(() => import('src/screens/pages/Reports/POOrderNC'))
);
const ReportsPOOrderNCBill = Loader(
  lazy(() => import('src/screens/pages/Reports/POOrderNCBill'))
);
const ReportsPOCashClose = Loader(
  lazy(() => import('src/screens/pages/Reports/POCashClose'))
);
const ReportsPOGroupWise = Loader(
  lazy(() => import('src/screens/pages/Reports/POGroupWise'))
);
const POSIssues = Loader(
  lazy(() => import('src/screens/pages/Reports/POIssues'))
);
const POSStocks = Loader(
  lazy(() => import('src/screens/pages/Reports/POStock'))
);
const POSProducts = Loader(
  lazy(() => import('src/screens/pages/Reports/POProductList'))
);
const POCancelOrders = Loader(
  lazy(() => import('src/screens/pages/Reports/POCancelOrders'))
);

const ACReceipts = Loader(
  lazy(() => import('src/screens/pages/Reports/ACReceipts'))
);

const BQSettings = Loader(
  lazy(() => import('src/screens/pages/Admin/BQSettings'))
);
const BQHall = Loader(lazy(() => import('src/screens/pages/Masters/BQHall')));
const BQFunction = Loader(
  lazy(() => import('src/screens/pages/Masters/BQFunction'))
);
const BQService = Loader(
  lazy(() => import('src/screens/pages/Masters/BQService'))
);
const BQBill = Loader(
  lazy(() => import('src/screens/pages/Transaction/BQBill'))
);
const BQBooking = Loader(
  lazy(() => import('src/screens/pages/Transaction/BQBooking'))
);
const BQCashier = Loader(
  lazy(() => import('src/screens/pages/Reports/BQCashier'))
);
const BQTax = Loader(lazy(() => import('src/screens/pages/Reports/BQTax')));
const ReportBQBooking = Loader(
  lazy(() => import('src/screens/pages/Reports/BQBooking'))
);

const JobEntry = Loader(
  lazy(() => import('src/screens/pages/Transaction/JobEntry'))
);
const JobAssignEntry = Loader(
  lazy(() => import('src/screens/pages/Transaction/JobAssignEntry'))
);
const JobWorkEntry = Loader(
  lazy(() => import('src/screens/pages/Transaction/JobWorkEntry'))
);

const TransGate = Loader(
  lazy(() => import('src/screens/pages/Transaction/GateEntry'))
);
const STPurchase = Loader(
  lazy(() => import('src/screens/pages/Transaction/STPurchaseEntry'))
);
const STIssue = Loader(
  lazy(() => import('src/screens/pages/Transaction/STIssueEntry'))
);
const STPurchases = Loader(
  lazy(() => import('src/screens/pages/Reports/STPurchases'))
);
const STIssues = Loader(
  lazy(() => import('src/screens/pages/Reports/STIssue'))
);
const STIssueDept = Loader(
  lazy(() => import('src/screens/pages/Reports/STIssueDept'))
);
const STStock = Loader(lazy(() => import('src/screens/pages/Reports/STStock')));
const STCounterStock = Loader(
  lazy(() => import('src/screens/pages/Reports/STCounterStock'))
);
const STGates = Loader(lazy(() => import('src/screens/pages/Reports/STGate')));

// Human Resource
const EMAttendance = Loader(
  lazy(() => import('src/screens/pages/Transaction/EMAttendance'))
);
const EMPayRoll = Loader(
  lazy(() => import('src/screens/pages/Transaction/EMPayRoll'))
);
const EMWages = Loader(
  lazy(() => import('src/screens/pages/Transaction/EMWages'))
);
const REMAttLog = Loader(
  lazy(() => import('src/screens/pages/Reports/EMAttLog'))
);
const REMPayRoll = Loader(
  lazy(() => import('src/screens/pages/Reports/EMPayRoll'))
);
const REMWages = Loader(
  lazy(() => import('src/screens/pages/Reports/EMWages'))
);
// Pages

const Auth = Loader(lazy(() => import('src/screens/auth')));

// Dashboards
const Crypto = Loader(lazy(() => import('src/screens/dashboards/Crypto')));
const Audit = Loader(lazy(() => import('src/screens/pages/Transaction/Audit')));

const Status = Loader(
  lazy(() => import('src/screens/pages/FrontOffice/RoomStatus'))
);
const Availability = Loader(
  lazy(() => import('src/screens/pages/FrontOffice/Availability'))
);
const Search = Loader(
  lazy(() => import('src/screens/pages/FrontOffice/Search'))
);
const InHouse = Loader(
  lazy(() => import('src/screens/pages/FrontOffice/InHouse'))
);
const BQSearch = Loader(
  lazy(() => import('src/screens/pages/Reports/BQSearch'))
);

// Status
const Status404 = Loader(
  lazy(() => import('src/screens/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/screens/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/screens/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/screens/pages/Status/Maintenance'))
);

const ChangePassword = Loader(
  lazy(() => import('src/screens/management/profile/ChangePassword'))
);
const Profile = Loader(
  lazy(() => import('src/screens/management/profile/Profile'))
);
const Help = Loader(lazy(() => import('src/screens/management/Help')));
const Privacy = Loader(lazy(() => import('src/screens/management/Privacy')));
const AboutUs = Loader(lazy(() => import('src/screens/management/AboutUs')));
const ContactUs = Loader(
  lazy(() => import('src/screens/management/ContactUs'))
);
const Pricing = Loader(lazy(() => import('src/screens/management/Pricing')));
const Terms = Loader(lazy(() => import('src/screens/management/Terms')));
const PaymentPrivacy = Loader(
  lazy(() => import('src/screens/management/PaymentPrivacy'))
);
const Policy = Loader(lazy(() => import('src/screens/management/Policy')));
const Disclaimer = Loader(
  lazy(() => import('src/screens/management/Disclaimer'))
);
const Intellectual = Loader(
  lazy(() => import('src/screens/management/Intellectual'))
);
const ProcessFlow = Loader(
  lazy(() => import('src/screens/management/ProcessFlow'))
);

const GuestLogin = Loader(lazy(() => import('src/screens/auth/GuestLogin')));
const POSGuestOrder = Loader(
  lazy(() => import('src/screens/pages/Transaction/POSGuestOrder'))
);
const HotelServicePage = Loader(
  lazy(() => import('src/screens/pages/FrontOffice/RoomQR/HotelServicePage'))
);
const ReviewForm = Loader(
  lazy(() => import('src/screens/pages/FrontOffice/RoomQR/ReviewForm'))
);
const GeneralInfoPage = Loader(
  lazy(() => import('src/screens/pages/FrontOffice/RoomQR/GeneralInfoPage'))
);
const FacilitiesPage = Loader(
  lazy(() => import('src/screens/pages/FrontOffice/RoomQR/FacilitiesPage'))
);
const HotelServicePage1 = Loader(
  lazy(() => import('src/screens/pages/FrontOffice/RoomQR/HotelServicePage1'))
);
const ScanIDProof = Loader(
  lazy(() => import('src/screens/pages/FrontOffice/RoomQR/ScanIDProof'))
);
const FillAddress = Loader(
  lazy(() => import('src/screens/pages/FrontOffice/RoomQR/FillAddress'))
);
const RoomAvailability = Loader(
  lazy(() => import('src/screens/pages/FrontOffice/RoomQR/RoomAvailability'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Crypto />
      },
      {
        path: 'audit',
        element: <Audit />
      },
      {
        path: 'dashboard',
        element: <Crypto />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      }
    ]
  },
  {
    path: 'admin',
    element: <SidebarLayout />,
    children: [
      {
        path: 'company',
        element: <AdminCompany />
      },
      {
        path: 'systems',
        element: <Systems />
      },
      {
        path: 'users',
        element: <AdminUsers />
      },
      {
        path: 'usermenu',
        element: <UserMenu />
      },
      {
        path: 'country',
        element: <AdminCountry />
      },
      {
        path: 'state',
        element: <AdminState />
      },
      {
        path: 'mode',
        element: <AdminModes />
      },
      {
        path: 'taxgroup',
        element: <AdminTaxGroup />
      },
      {
        path: 'tax',
        element: <AdminTax />
      },
      {
        path: 'uom',
        element: <AdminUom />
      },
      {
        path: 'fosettings',
        element: <AdminFOSettings />
      },
      {
        path: 'stsettings',
        element: <AdminSTSettings />
      },
      {
        path: 'pooutlet',
        element: <AdminPOOutlet />
      },
      {
        path: 'bqsettings',
        element: <BQSettings />
      },
      {
        path: 'acsettings',
        element: <ACSettings />
      },
      {
        path: 'smsettings',
        element: <SMSettings />
      }
    ]
  },
  {
    path: 'masters',
    element: <SidebarLayout />,
    children: [
      {
        path: 'foroomtype',
        element: <MasterFORoomType />
      },
      {
        path: 'foroomtariff',
        element: <MasterFORoomTariff />
      },
      {
        path: 'forooms',
        element: <MasterFORooms />
      },
      {
        path: 'fomplans',
        element: <FOMPlans />
      },
      {
        path: 'fomsource',
        element: <FOMSource />
      },
      {
        path: 'fomidtype',
        element: <FOMIDType />
      },
      {
        path: 'fompurpose',
        element: <FOMPurpose />
      },
      {
        path: 'fomhead',
        element: <FOMHead />
      },
      {
        path: 'addrbook',
        element: <AddrBook />
      },
      {
        path: 'achead',
        element: <ACMHead />
      },
      {
        path: 'acmcompany',
        element: <ACMCompany />
      },
      {
        path: 'supplier',
        element: <MastersSupplier />
      },
      {
        path: 'stgroup',
        element: <STGroup />
      },
      {
        path: 'stproduct',
        element: <STProduct />
      },
      {
        path: 'department',
        element: <Department />
      },
      {
        path: 'employee',
        element: <MastersEmployee />
      },
      {
        path: 'jobtype',
        element: <MTMJobType />
      },
      {
        path: 'itemtype',
        element: <MTMItemType />
      },
      {
        path: 'potax',
        element: <MastersPOTax />
      },
      {
        path: 'pogroup',
        element: <MastersPOGroup />
      },
      {
        path: 'poproduct',
        element: <MastersPOProduct />
      },
      {
        path: 'poproductblock',
        element: <POSProductBlock />
      },
      {
        path: 'potable',
        element: <MastersPOTable />
      },
      {
        path: 'poreceipe',
        element: <POSReceipe />
      },
      {
        path: 'poreceipe/:id/:prid',
        element: <POSReceipe />
      },
      {
        path: 'hall',
        element: <BQHall />
      },
      {
        path: 'function',
        element: <BQFunction />
      },
      {
        path: 'foodservice',
        element: <BQService />
      }
    ]
  },
  {
    path: 'transaction',
    element: <SidebarLayout />,
    children: [
      {
        path: 'foreservation',
        element: <FOEReservation />
      },
      {
        path: 'foreservation/:id',
        element: <FOEReservation />
      },
      {
        path: 'foreseradv',
        element: <FOEReserAdv />
      },
      {
        path: 'foresercancel',
        element: <FOEReserCancel />
      },
      {
        path: 'focheckin',
        element: <FOECheckin />
      },
      {
        path: 'focheckin/:id',
        element: <FOECheckin />
      },
      {
        path: 'focheckin/:flag/:room',
        element: <FOECheckin />
      },
      {
        path: 'focheckinadv',
        element: <FOECheckinAdv />
      },
      {
        path: 'focheckinadv/:id',
        element: <FOECheckinAdv />
      },
      {
        path: 'focheckinadv/:type/:open/:checkin',
        element: <FOECheckinAdv />
      },
      {
        path: 'fopostings',
        element: <FOEPostings />
      },
      {
        path: 'fopostings/:id',
        element: <FOEPostings />
      },
      {
        path: 'fopostings/:type/:open/:checkin/:room',
        element: <FOEPostings />
      },
      {
        path: 'focheckout',
        element: <FOECheckout />
      },
      {
        path: 'focheckout/:id',
        element: <FOECheckout />
      },
      {
        path: 'focheckout/:flag/:room',
        element: <FOECheckout />
      },
      {
        path: 'focheckout/:flag/:room/:view',
        element: <FOECheckout />
      },
      {
        path: 'fohousekeeping/:flag/:room',
        element: <FOEHouseKeeping />
      },
      {
        path: 'foroomshift',
        element: <FOERoomShift />
      },
      {
        path: 'foroomshift/:checkin/:room',
        element: <FOERoomShift />
      },
      {
        path: 'fopaxalter',
        element: <FOEPaxAlter />
      },
      {
        path: 'fopaxalter/:checkin/:room',
        element: <FOEPaxAlter />
      },
      {
        path: 'folinkrooms/:checkin/:room',
        element: <FOLinkRooms />
      },
      {
        path: 'potablelist',
        element: <POSKOTTableList />
      },
      {
        path: 'potablelist/:bill',
        element: <POSKOTTableList />
      },
      {
        path: 'pokot',
        element: <POSKOTEntry />
      },
      {
        path: 'pokotnc',
        element: <POSKOTNCEntry />
      },
      {
        path: 'pokot/:checkin/:tbl/:waiter',
        element: <POSKOTEntry />
      },
      {
        path: 'pokot/:checkin/:tbl/:waiter/:kot',
        element: <POSKOTEntry />
      },
      {
        path: 'pokotcancel/:checkin/:tbl/:waiter/:kot',
        element: <POSKOTCancel />
      },
      {
        path: 'porunningordes',
        element: <POSRunningOrders />
      },
      {
        path: 'posettle',
        element: <POSSettle />
      },
      {
        path: 'posettle/:bid',
        element: <POSSettle />
      },
      {
        path: 'poissue',
        element: <POSIssue />
      },
      {
        path: 'poissue/:id',
        element: <POSIssue />
      },
      {
        path: 'bqbill',
        element: <BQBill />
      },
      {
        path: 'bqbill/:id',
        element: <BQBill />
      },
      {
        path: 'bqbooking',
        element: <BQBooking />
      },
      {
        path: 'bqbooking/:id',
        element: <BQBooking />
      },
      { path: 'stpurchase', element: <STPurchase /> },
      { path: 'stpurchase/:id', element: <STPurchase /> },
      { path: 'stissue', element: <STIssue /> },
      { path: 'stissue/:id', element: <STIssue /> }
    ]
  },
  {
    path: 'entry',
    element: <SidebarLayout />,
    children: [
      {
        path: 'acexpenses',
        element: <ACEExpenses />
      },
      {
        path: 'acincomes',
        element: <ACEIncomes />
      },
      {
        path: 'gateentry',
        element: <TransGate />
      },
      {
        path: 'acpayment',
        element: <ACPayment />
      },
      {
        path: 'acpayment/:id',
        element: <ACPayment />
      },
      {
        path: 'acreceipt',
        element: <ACReceipt />
      },
      {
        path: 'acreceipt/:id',
        element: <ACReceipt />
      },
      {
        path: 'pocashclose',
        element: <POSCashClose />
      },
      {
        path: 'jobentry',
        element: <JobEntry />
      },
      {
        path: 'jobassign',
        element: <JobAssignEntry />
      },
      {
        path: 'jobwork',
        element: <JobWorkEntry />
      },
      {
        path: 'attendance',
        element: <EMAttendance />
      },
      {
        path: 'payroll',
        element: <EMPayRoll />
      },
      {
        path: 'wages',
        element: <EMWages />
      }
    ]
  },
  {
    path: 'reports',
    element: <SidebarLayout />,
    children: [
      {
        path: 'foreservation',
        element: <FORReservation />
      },
      {
        path: 'fooccchart',
        element: <FOROccChart />
      },
      {
        path: 'dfr',
        element: <FODFRCheckout />
      },
      {
        path: 'fopolice',
        element: <FOPolice />
      },
      {
        path: 'focashier',
        element: <FORCashier />
      },
      {
        path: 'focheckin',
        element: <FOCheckinAC />
      },
      {
        path: 'focheckout',
        element: <FOCheckoutAC />
      },
      {
        path: 'forevenue',
        element: <FORevenueSummary />
      },
      {
        path: 'fotax',
        element: <FOTax />
      },
      {
        path: 'fomonthly',
        element: <FOMonthly />
      },
      {
        path: 'focharges',
        element: <FOCharges />
      },
      {
        path: 'fodaybook',
        element: <FODayBook />
      },
      {
        path: 'footasales',
        element: <FOOTASales />
      },
      {
        path: 'fotourism',
        element: <FOTourism />
      },
      {
        path: 'fooutstanding',
        element: <FOOutstanding />
      },
      {
        path: 'foratepost',
        element: <FORatePost />
      },
      {
        path: 'fofoodcoupon',
        element: <FOFoodCoupon />
      },
      {
        path: 'foreceipts',
        element: <FOReceipts />
      },
      {
        path: 'foprinttrans/:id',
        element: <FOPrintTrans />
      },
      {
        path: 'foprintcitrans/:id',
        element: <FOPrintCITrans />
      },
      {
        path: 'log',
        element: <FOLog />
      },
      {
        path: 'polistorder',
        element: <POListOrders />
      },
      {
        path: 'polistbills',
        element: <POListBills />
      },
      {
        path: 'cashier',
        element: <POReports />
      },
      {
        path: 'waiterwise',
        element: <ReportsPOWaiterWise />
      },
      {
        path: 'itemwise',
        element: <ReportsPOItemWise />
      },
      {
        path: 'poissue',
        element: <POSIssues />
      },
      {
        path: 'postock',
        element: <POSStocks />
      },
      {
        path: 'poproducts',
        element: <POSProducts />
      },
      {
        path: 'pocancelorder',
        element: <POCancelOrders />
      },
      {
        path: 'expenses',
        element: <ReportsExpenses />
      },
      {
        path: 'incomes',
        element: <ReportsIncomes />
      },
      {
        path: 'acoutstanding',
        element: <ACOutstanding />
      },
      {
        path: 'acsuppoutstanding',
        element: <ACSuppOutstanding />
      },
      {
        path: 'acsuppoutstanddet/:id/:name',
        element: <ACSuppOutstDetails />
      },
      {
        path: 'acprofitandloss',
        element: <ACProfitAndLoss />
      },
      {
        path: 'paymentreceipts',
        element: <ACPaymentReceipts />
      },
      {
        path: 'poorder',
        element: <ReportsPOOrder />
      },
      {
        path: 'poordernc',
        element: <ReportsPOOrderNC />
      },
      {
        path: 'poorderncbill',
        element: <ReportsPOOrderNCBill />
      },
      {
        path: 'pocashclose',
        element: <ReportsPOCashClose />
      },
      {
        path: 'groupwise',
        element: <ReportsPOGroupWise />
      },
      {
        path: 'hallbills',
        element: <BQCashier />
      },
      {
        path: 'halltax',
        element: <BQTax />
      },
      {
        path: 'stpurchases',
        element: <STPurchases />
      },
      {
        path: 'stissue',
        element: <STIssues />
      },
      {
        path: 'stissuedept',
        element: <STIssueDept />
      },
      {
        path: 'ststock',
        element: <STStock />
      },
      {
        path: 'stcounterstock',
        element: <STCounterStock />
      },
      {
        path: 'stgates',
        element: <STGates />
      },
      {
        path: 'hallbooking',
        element: <ReportBQBooking />
      },
      {
        path: 'receipts',
        element: <ACReceipts />
      },
      {
        path: 'expensessumm',
        element: <RepExpensesSumm />
      },
      {
        path: 'expensescol',
        element: <RepExpensesCol />
      },
      {
        path: 'expensesdetails',
        element: <RepExpensesDetails />
      },
      {
        path: 'payroll',
        element: <REMPayRoll />
      },
      {
        path: 'wages',
        element: <REMWages />
      },
      {
        path: 'attlog',
        element: <REMAttLog />
      }
    ]
  },
  {
    path: 'status',
    element: <SidebarLayout />,
    children: [
      {
        path: 'status',
        element: <Status />
      },
      {
        path: 'availabilty',
        element: <Availability />
      },
      {
        path: 'search',
        element: <Search />
      },
      {
        path: 'inhouse',
        element: <InHouse />
      },
      {
        path: 'bqsearch',
        element: <BQSearch />
      },
      {
        path: 'invoice',
        element: <Invoice />
      }
    ]
  },
  {
    path: 'management/profile',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Profile />
      },
      {
        path: 'changepassword',
        element: <ChangePassword />
      }
    ]
  },
  {
    path: '*',
    element: <Status404 />
  }
];

const authroutes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Auth />
      },
      {
        path: 'home',
        element: <Home />
      },
      {
        path: 'company',
        element: <AdminCompany />
      },
      {
        path: 'forget',
        element: <Forget />
      },
      {
        path: 'foreservation',
        element: <FORReservation1 />
      },
      {
        path: 'help',
        element: <Help />
      },
      {
        path: 'privacy',
        element: <Privacy />
      },
      {
        path: 'aboutus',
        element: <AboutUs />
      },
      {
        path: 'contactus',
        element: <ContactUs />
      },
      {
        path: 'pricing',
        element: <Pricing />
      },
      {
        path: 'terms',
        element: <Terms />
      },
      {
        path: 'paymentprivacy',
        element: <PaymentPrivacy />
      },
      {
        path: 'policy',
        element: <Policy />
      },
      {
        path: 'disclaimer',
        element: <Disclaimer />
      },
      {
        path: 'intellectual',
        element: <Intellectual />
      },
      {
        path: 'processflow',
        element: <ProcessFlow />
      }
    ]
  },
  {
    path: '*',
    element: <Auth />
  }
];

const homeroutes: RouteObject[] = [
  {
    path: '',
    element: <HomeLayout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: 'home',
        element: <Home />
      },
      {
        path: 'home/help',
        element: <Help />
      },
      {
        path: 'home/privacy',
        element: <Privacy />
      },
      {
        path: 'home/aboutus',
        element: <AboutUs />
      },
      {
        path: 'home/contactus',
        element: <ContactUs />
      },
      {
        path: 'home/pricing',
        element: <Pricing />
      },
      {
        path: 'home/terms',
        element: <Terms />
      },
      {
        path: 'home/paymentprivacy',
        element: <PaymentPrivacy />
      },
      {
        path: 'home/policy',
        element: <Policy />
      },
      {
        path: 'home/disclaimer',
        element: <Disclaimer />
      },
      {
        path: 'home/intellectual',
        element: <Intellectual />
      },
      {
        path: 'home/processflow',
        element: <ProcessFlow />
      }
    ]
  },
  {
    path: '*',
    element: <Home />
  }
];

const guestauthroutes: RouteObject[] = [
  {
    path: '',
    element: <GuestLayout />,
    children: [
      {
        path: 'guest/:company/:outletid/:checkin/:tbl',
        element: <GuestLogin />
      },
      {
        path: 'guest/servicepage/:id/:cid',
        element: <HotelServicePage />
      },
      {
        path: 'guest/servicepage1/:cid',
        element: <HotelServicePage1 />
      }
    ]
  },
  {
    path: '*',
    element: <GuestLogin />
  }
];

const guestroutes: RouteObject[] = [
  {
    path: '',
    element: <GuestLayout />,
    children: [
      {
        path: 'guest/poguestorder/:company/:outletid/:checkin/:tbl/:waiter',
        element: <POSGuestOrder />
      },
      {
        path: 'guest/servicepage/:id/:cid',
        element: <HotelServicePage />
      },
      {
        path: 'guest/reviewform/:id/:cid/:guestName/:roomName/:mobile',
        element: <ReviewForm />
      },
      {
        path: 'guest/generalinfo',
        element: <GeneralInfoPage />
      },
      {
        path: 'guest/facilities',
        element: <FacilitiesPage />
      },
      {
        path: 'guest/generalinfo',
        element: <GeneralInfoPage />
      },
      {
        path: 'guest/servicepage1/:cid',
        element: <HotelServicePage1 />
      },
      {
        path: 'guest/scanidproof',
        element: <ScanIDProof />
      },
      {
        path: 'guest/filladdress',
        element: <FillAddress />
      },
      {
        path: 'guest/RoomAvailability',
        element: <RoomAvailability />
      }
    ]
  },
  {
    path: '*',
    element: <GuestLogin />
  }
];

const kdsroutes: RouteObject[] = [
  {
    path: '',
    element: <GuestLayout />,
    children: [
      {
        path: '/',
        element: <POSRunningOrders />
      }
    ]
  },
  {
    path: '*',
    element: <POSRunningOrders />
  }
];

export default routes;
export { authroutes, homeroutes, guestroutes, guestauthroutes, kdsroutes };
