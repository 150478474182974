import Key from './key.png';
import Bluetech from './logo/bluetech.png';
import { default as BlueHMS } from './logo/BlueHMS.png';
import { default as LoginImg, default as ReactLogo } from './logo/logo.png';
import ReactLogo1 from './logo/logo1.png';

function BTLogo() {
  return <img src={ReactLogo} alt="BlueTech" />;
}

export function BTLogo1() {
  return <img height={100} src={ReactLogo1} alt="BlueTech" />;
}

export function BTLoginImg() {
  return <img src={LoginImg} alt="BlueTech" />;
}

export function KeyImg() {
  return <img src={Key} alt="Key" />;
}

export function BluetechLogo() {
  return <img src={Bluetech} alt="BlueTech" />;
}

export function BlueHMSLogo() {
  return <img src={BlueHMS} alt="BlueHMS" />;
}

export default BTLogo;
