import {
  Box,
  Button,
  Card,
  Container,
  CssBaseline,
  Grid,
  Link,
  Typography,
  styled
} from '@mui/material';
import PropTypes from 'prop-types';
import { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, Link as RouterLink } from 'react-router-dom';
import { BlueTechLogoImg } from 'src/components/Logo1';

interface BaseLayoutProps {
  children?: ReactNode;
}

const AuthWrapper = styled(Box)(
  () => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    align-items: center;
`
);

const menuItems = [
  { title: 'Home', link: '/home' },
  { title: 'About Us', link: '/aboutus' },
  { title: 'Contact Us', link: '/contactus' },
  { title: 'Pricing', link: '/pricing' },
  { title: 'Privacy Policy', link: '/paymentprivacy' },
  { title: 'Terms & Conditions', link: '/terms' },
  { title: 'Return, Refund and Cancellation Policy', link: '/policy' },
  { title: 'Disclaimer', link: '/disclaimer' },
  { title: 'Intellectual Property', link: '/intellectual' },
  { title: 'Process Flow', link: '/processflow' }
];

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  return (
    <>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />

        {/* Left Side Content */}
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            display: { xs: 'none', md: 'block' } // Hide on small and medium screens
          }}
        >
          <Container maxWidth="lg">
            <Card sx={{ p: 10, mb: 10, borderRadius: 4 }}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <BlueTechLogoImg />
              </Box>

              <Grid
                container
                direction="row"
                sx={{
                  height: 50,
                  background: '#F0EDCF',
                  alignItems: 'center',
                  p: 2,
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                  mb: 2
                }}
              >
                {menuItems.slice(0, 4).map((item) => (
                  <Button
                    key={item.title}
                    component={RouterLink}
                    to={item.link}
                    size="small"
                    variant="text"
                    sx={{
                      m: 1,
                      color: 'blue',
                      '&:hover': { backgroundColor: '#40A2D8' }
                    }}
                  >
                    {item.title}
                  </Button>
                ))}
              </Grid>

              <Typography variant="h5" align="center" gutterBottom>
                🖥️ Experience the Future of Hospitality with BlueTech's BlueHMS
                🖥️
              </Typography>
              <Typography paragraph>
                Welcome to a new era of hotel management! At BlueHMS, we believe
                in embracing innovation and technology to deliver exceptional
                hospitality experiences. BlueHMS, our leading Hotel Management
                Software, provides a comprehensive, all-in-one platform designed
                to simplify your hotel operations, enhance efficiency, and
                elevate guest satisfaction.
              </Typography>
              <Typography paragraph>
                Here's what BlueHMS brings to your hotel: Effortless Booking,
                Integrated POS, Smart Inventory Management, Data-Driven
                Insights, Seamless Integration.
              </Typography>
              <Link href="https://home.bluehms.in" rel="noopener noreferrer">
                See More
              </Link>

              <Grid container direction="row">
                {menuItems.slice(4).map((item) => (
                  <Button
                    key={item.title}
                    component={RouterLink}
                    to={item.link}
                    size="small"
                    variant="text"
                    sx={{
                      m: 1,
                      color: 'blue',
                      '&:hover': { backgroundColor: '#40A2D8' }
                    }}
                  >
                    {item.title}
                  </Button>
                ))}
              </Grid>
            </Card>
          </Container>
        </Grid>

        {/* Right Side Content */}
        <AuthWrapper>
          <Helmet>
            <title>BlueTech Hotel Management</title>
          </Helmet>
          <Container maxWidth="lg">
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: '100vh' }}
            >
              <Grid item xs={12}>
                <Card sx={{ p: 1, mb: 1, borderRadius: 4 }}>
                  {children || <Outlet />}
                </Card>
              </Grid>
            </Grid>
          </Container>
        </AuthWrapper>
      </Grid>
    </>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node
};

export default BaseLayout;
