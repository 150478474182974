import { ExpandLess, ExpandMore } from '@mui/icons-material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import SettingsIcon from '@mui/icons-material/Settings';
import SummarizeIcon from '@mui/icons-material/Summarize';
import {
  Box,
  Button,
  Collapse,
  List,
  ListItem,
  ListSubheader,
  alpha,
  styled
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink as RouterLink } from 'react-router-dom';
import { retrieveSetting, storeSetting } from 'src/Common/common';
import BTComboBox from 'src/components/BTComboBox';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { useGetPOAAreasQuery } from 'src/redux/services/poaareaApi';
import { useGetPOAOutletsQuery } from 'src/redux/services/poaoutletApi';
import { RootState } from 'src/redux/store';
import * as agen from '../../../../redux/services/agen';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(0)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(0)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[100]};
      padding: ${theme.spacing(0, 0)};
      line-height: 1;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(14)};
            text-transform: uppercase;
            color: ${theme.palette.primary.light};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(0, 3)};
          font-size: ${theme.typography.pxToRem(14)};
          font-weight: 200;

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(18)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(18)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(5)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 2)};

              .MuiBadge-root {
                right: ${theme.spacing(2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const SidebarMenu = () => {
  const dispatch = useDispatch();
  const gdata = useSelector((state: RootState) => state.gdata);
  const glogic = useSelector((state: RootState) => state.glogic.glogic);
  const { closeSidebar } = useContext(SidebarContext);

  const { data: outletData } = useGetPOAOutletsQuery(gdata.company);

  const { data: areaData } = useGetPOAAreasQuery({
    cid: gdata.company,
    olid: glogic.outletid || 2
  });

  const [multiOutlet, setMultiOutlet] = useState(false);
  const [multiArea, setMultiArea] = useState(false);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      bt_pooutlet: Number(retrieveSetting('outlet')),
      bt_poarea: Number(retrieveSetting('area'))
    }
  });

  const outlets = outletData?.map((value, i) => {
    return {
      label: value.ol_sname,
      id: value.ol_id,
      value: value
    };
  });

  const areas = (areaData || []).map((value, i) => {
    return {
      label: value.ar_name,
      id: value.ar_id,
      value: value
    };
  });

  useEffect(() => {
    if (outlets?.length > 0 && (!glogic.outletid || !gdata.outlet)) {
      const defid = Number(retrieveSetting('outlet')) || Number(outlets[0].id);
      setValue('bt_pooutlet', defid);

      const outlet = outlets.find((v) => defid == v.id) ?? outlets[0];
      dispatch(
        agen.setLogic({
          pscompany: outlet.value.ol_pscompany,
          outletid: outlet.id,
          billing: outlet.value.ol_billing,
          kotprinter: outlet.value.pr_kotprinter,
          billprinter: outlet.value.pr_billprinter,
          windowsservice: outlet.value.ol_windowsservice == '1'
        })
      );
    }
    setMultiOutlet(outlets?.length > 1);
  }, [outlets]);

  useEffect(() => {
    if (areas?.length > 0 && !glogic.area) {
      const defid = Number(retrieveSetting('area')) || Number(areas[0].id);
      setValue('bt_poarea', defid);

      const area = areas.find((v) => defid == v.id) ?? areas[0];
      dispatch(
        agen.setLogic({
          area: area.value.ar_id,
          areaname: area.value.ar_name
        })
      );
    }
    setMultiArea(areas?.length > 1);
  }, [areas]);

  const onSubmit = (values) => {
    console.log(values);
  };

  const handleOutletChange = (v) => {
    dispatch(
      agen.setLogic({
        pscompany: v.value.ol_pscompany,
        outletid: v.id,
        billing: v.value.ol_billing,
        kotprinter: v.value.pr_kotprinter,
        billprinter: v.value.pr_billprinter,
        windowsservice: v.value.ol_windowsservice == '1'
      })
    );
    storeSetting('outlet', v.id);
  };

  const handleAreaChange = (v) => {
    dispatch(
      agen.setLogic({
        area: v.value.ar_id,
        areaname: v.value.ar_name
      })
    );
    storeSetting('area', v.id);
  };

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  const handleClick = () => {
    setOpen(!open);

    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
  };

  const handleClick1 = () => {
    setOpen1(!open1);

    setOpen(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
  };

  const handleClick2 = () => {
    setOpen2(!open2);

    setOpen(false);
    setOpen1(false);
    setOpen3(false);
    setOpen4(false);
  };

  const handleClick3 = () => {
    setOpen3(!open3);

    setOpen(false);
    setOpen1(false);
    setOpen2(false);
    setOpen4(false);
  };

  const handleClick4 = () => {
    setOpen4(!open4);

    setOpen(false);
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
  };

  const isAdmin = gdata.menu?.find((v) => v.mn_link === '/admin/company');
  const isMultiPOS = gdata.menu?.find((v) => v.mn_link === '/admin/pooutlet');

  interface GroupedItems {
    [key: string]: (bt_amenu & bt_ausermenu & bt_amodules)[];
  }

  function groupMenuItemsByMdName(mnParent: number): GroupedItems {
    const groupedItems: GroupedItems = gdata.menu
      ?.filter((item) => item.mn_parent === mnParent)
      .reduce(
        (acc: GroupedItems, item: bt_amenu & bt_ausermenu & bt_amodules) => {
          const groupName: string = item.md_name || 'Others';
          if (!acc[groupName]) {
            acc[groupName] = [];
          }
          acc[groupName].push(item);
          return acc;
        },
        {}
      );

    return groupedItems;
  }

  return (
    <>
      <MenuWrapper>
        {isAdmin ? (
          <List component="div">
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/dashboard/"
                    startIcon={<DesignServicesTwoToneIcon />}
                  >
                    Dashboards
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
        ) : null}
        {multiOutlet && isMultiPOS ? (
          <Box onSubmit={handleSubmit(onSubmit)} component="form">
            <List component="div">
              <SubMenuWrapper>
                <List
                  component="div"
                  style={{
                    background: 'white',
                    height: 50,
                    width: '100%',
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <ListItem component="div">
                    <BTComboBox
                      name="bt_pooutlet"
                      label="Outlet"
                      data={outlets}
                      control={control}
                      onValChange={handleOutletChange}
                    />
                  </ListItem>
                </List>
              </SubMenuWrapper>
            </List>
          </Box>
        ) : null}
        {multiArea ? (
          <Box
            onSubmit={handleSubmit((val) => {
              return;
            })}
            component="form"
          >
            <List component="div">
              <SubMenuWrapper>
                <List
                  component="div"
                  style={{
                    background: 'white',
                    height: 50,
                    width: '100%',
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <ListItem component="div">
                    <BTComboBox
                      name="bt_poarea"
                      label="Area"
                      data={areas}
                      control={control}
                      onValChange={handleAreaChange}
                    />
                  </ListItem>
                </List>
              </SubMenuWrapper>
            </List>
          </Box>
        ) : null}
        <List
          component="div"
          subheader={
            <Button
              onClick={handleClick}
              endIcon={open ? <ExpandLess /> : <ExpandMore />}
            >
              <ListSubheader component="div" disableSticky>
                Admin
              </ListSubheader>
            </Button>
          }
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <SubMenuWrapper>
              <List component="div">
                {Object.entries(groupMenuItemsByMdName(1)).map(
                  ([groupName, items], index) => (
                    <List
                      key={groupName}
                      component="div"
                      subheader={
                        <ListSubheader component="div" disableSticky>
                          {groupName}
                        </ListSubheader>
                      }
                    >
                      <SubMenuWrapper>
                        <List component="div">
                          {items.map((item, index) => {
                            let svgIcon = <SettingsIcon />;

                            return (
                              <ListItem key={index} component="div">
                                <Button
                                  disableRipple
                                  component={RouterLink}
                                  onClick={closeSidebar}
                                  to={item.mn_link}
                                  startIcon={svgIcon}
                                >
                                  {item.mn_name}
                                </Button>
                              </ListItem>
                            );
                          })}
                        </List>
                      </SubMenuWrapper>
                    </List>
                  )
                )}
              </List>
            </SubMenuWrapper>
          </Collapse>
        </List>
        <List
          component="div"
          subheader={
            <Button
              onClick={handleClick1}
              endIcon={open1 ? <ExpandLess /> : <ExpandMore />}
            >
              <ListSubheader component="div" disableSticky>
                Master
              </ListSubheader>
            </Button>
          }
        >
          <Collapse in={open1} timeout="auto" unmountOnExit>
            <SubMenuWrapper>
              <List component="div">
                {Object.entries(groupMenuItemsByMdName(2)).map(
                  ([groupName, items], index) => (
                    <List
                      key={groupName}
                      component="div"
                      subheader={
                        <ListSubheader
                          component="div"
                          disableSticky
                          sx={{ color: '#000000' }}
                        >
                          {groupName}
                        </ListSubheader>
                      }
                    >
                      <SubMenuWrapper>
                        <List component="div">
                          {items.map((item, index) => {
                            let svgIcon = <AdminPanelSettingsIcon />;

                            return (
                              <ListItem key={index} component="div">
                                <Button
                                  disableRipple
                                  component={RouterLink}
                                  onClick={closeSidebar}
                                  to={item.mn_link}
                                  startIcon={svgIcon}
                                >
                                  {item.mn_name}
                                </Button>
                              </ListItem>
                            );
                          })}
                        </List>
                      </SubMenuWrapper>
                    </List>
                  )
                )}
              </List>
            </SubMenuWrapper>
          </Collapse>
        </List>
        <List
          component="div"
          subheader={
            <Button
              onClick={handleClick2}
              endIcon={open2 ? <ExpandLess /> : <ExpandMore />}
            >
              <ListSubheader component="div" disableSticky>
                Transaction
              </ListSubheader>
            </Button>
          }
        >
          <Collapse in={open2} timeout="auto" unmountOnExit>
            <SubMenuWrapper>
              <List component="div">
                {Object.entries(groupMenuItemsByMdName(3)).map(
                  ([groupName, items], index) => (
                    <List
                      key={groupName}
                      component="div"
                      subheader={
                        <ListSubheader component="div" disableSticky>
                          {groupName}
                        </ListSubheader>
                      }
                    >
                      <SubMenuWrapper>
                        <List component="div">
                          {items.map((item, index) => {
                            let svgIcon = <ApartmentIcon />;

                            return (
                              <ListItem key={index} component="div">
                                <Button
                                  disableRipple
                                  component={RouterLink}
                                  onClick={closeSidebar}
                                  to={item.mn_link}
                                  startIcon={svgIcon}
                                >
                                  {item.mn_name}
                                </Button>
                              </ListItem>
                            );
                          })}
                        </List>
                      </SubMenuWrapper>
                    </List>
                  )
                )}
              </List>
            </SubMenuWrapper>
          </Collapse>
        </List>
        <List
          component="div"
          subheader={
            <Button
              onClick={handleClick3}
              endIcon={open3 ? <ExpandLess /> : <ExpandMore />}
            >
              <ListSubheader component="div" disableSticky>
                Entry
              </ListSubheader>
            </Button>
          }
        >
          <Collapse in={open3} timeout="auto" unmountOnExit>
            <SubMenuWrapper>
              <List component="div">
                {Object.entries(groupMenuItemsByMdName(4)).map(
                  ([groupName, items], index) => (
                    <List
                      key={groupName}
                      component="div"
                      subheader={
                        <ListSubheader component="div" disableSticky>
                          {groupName}
                        </ListSubheader>
                      }
                    >
                      <SubMenuWrapper>
                        <List component="div">
                          {items.map((item, index) => {
                            let svgIcon = <RoomPreferencesIcon />;

                            return (
                              <ListItem key={index} component="div">
                                <Button
                                  disableRipple
                                  component={RouterLink}
                                  onClick={closeSidebar}
                                  to={item.mn_link}
                                  startIcon={svgIcon}
                                >
                                  {item.mn_name}
                                </Button>
                              </ListItem>
                            );
                          })}
                        </List>
                      </SubMenuWrapper>
                    </List>
                  )
                )}
              </List>
            </SubMenuWrapper>
          </Collapse>
        </List>
        <List
          component="div"
          subheader={
            <Button
              onClick={handleClick4}
              endIcon={open4 ? <ExpandLess /> : <ExpandMore />}
            >
              <ListSubheader component="div" disableSticky>
                Reports
              </ListSubheader>
            </Button>
          }
        >
          <Collapse in={open4} timeout="auto" unmountOnExit>
            <SubMenuWrapper>
              <List component="div">
                {Object.entries(groupMenuItemsByMdName(5)).map(
                  ([groupName, items], index) => (
                    <List
                      key={groupName}
                      component="div"
                      subheader={
                        <ListSubheader component="div" disableSticky>
                          {groupName}
                        </ListSubheader>
                      }
                    >
                      <SubMenuWrapper>
                        <List component="div">
                          {items.map((item, index) => {
                            let svgIcon = <SummarizeIcon />;

                            return (
                              <ListItem key={index} component="div">
                                <Button
                                  disableRipple
                                  component={RouterLink}
                                  onClick={closeSidebar}
                                  to={item.mn_link}
                                  startIcon={svgIcon}
                                >
                                  {item.mn_name}
                                </Button>
                              </ListItem>
                            );
                          })}
                        </List>
                      </SubMenuWrapper>
                    </List>
                  )
                )}
              </List>
            </SubMenuWrapper>
          </Collapse>
        </List>
      </MenuWrapper>
    </>
  );
};

export default SidebarMenu;
