import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useLocation, useRoutes } from 'react-router-dom';
import { ConfirmationServiceProvider } from './components/Dialog/ConfirmationService';
import { RootState } from './redux/store';
import routes, {
  authroutes,
  homeroutes,
  guestroutes,
  guestauthroutes,
  kdsroutes
} from './router';
import ThemeProvider from './theme/ThemeProvider';
import useInactivityLogout from './hooks/useInactivityLogout ';

function App() {
  //useInactivityLogout(6000000);
  let location = useLocation();
  const gdata = useSelector((state: RootState) => state.gdata);
  const auth = useRoutes(authroutes);
  const home = useRoutes(homeroutes);
  const content = useRoutes(routes);
  const guest = useRoutes(guestroutes);
  const guestauth = useRoutes(guestauthroutes);
  const kds = useRoutes(kdsroutes);

  moment.tz.setDefault('Asia/Kolkata'); //'America/New_York' //'Asia/Calcutta  Asia/Kolkata  Etc/GMT0

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ConfirmationServiceProvider>
          <CssBaseline />
          {gdata.user && gdata.user !== ''
            ? location.pathname.indexOf('/guest') >= 0
              ? guest
              : location.pathname.indexOf('/kds') >= 0
              ? kds
              : content
            : location.pathname.indexOf('/home') >= 0
            ? home
            : location.pathname.indexOf('/guest') >= 0
            ? guestauth
            : auth}
        </ConfirmationServiceProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
