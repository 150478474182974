import {
  combineReducers,
  configureStore,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import Logout from 'src/screens/auth/Logout';
import notification from '../../components/Notification';
import { acsettingApi } from '../services/acasettingApi';
import { aceexpensesApi } from '../services/aceexpensesApi';
import { aceincomesApi } from '../services/aceincomesApi';
import { acepaymentApi } from '../services/acepaymentApi';
import { acereceiptApi } from '../services/acereceiptApi';
import { acmcompanyApi } from '../services/acmcompanyApi';
import { acmheadApi } from '../services/acmheadApi';
import { companyApi } from '../services/acompanyApi';
import { systemsApi } from '../services/asystemsApi';
import { countryApi } from '../services/acountryApi';
import { paymentApi } from '../services/apaymentApi';
import { acreportsApi } from '../services/acreportsApi';
import { logicSlice } from '../services/agen';
import { amenuApi } from '../services/amenuApi';
import { modeApi } from '../services/amodeApi';
import { amodulesApi } from '../services/amodulesApi';
import { smsApi } from '../services/asmsApi';
import { stateApi } from '../services/astateApi';
import { taxApi } from '../services/ataxApi';
import { taxgroupApi } from '../services/ataxgroupApi';
import { auditApi } from '../services/auditApi';
import { uomApi } from '../services/auomApi';
import { ausermenuApi } from '../services/ausermenuApi';
import { usersApi } from '../services/ausersApi';
import { authSlice } from '../services/authSlice';
import { bqsettingApi } from '../services/bqasettingApi';
import { bqebillApi } from '../services/bqebillApi';
import { bqebookingApi } from '../services/bqebookingApi';
import { bqmfunctionApi } from '../services/bqmfunctionApi';
import { bqmhallApi } from '../services/bqmhallApi';
import { bqmserviceApi } from '../services/bqmserviceApi';
import { bqreportsApi } from '../services/bqreportsApi';
import { dashboardApi } from '../services/dashboardApi';
import { emmdepartmentApi } from '../services/emmdepartmentApi';
import { emmdesignationApi } from '../services/emmdesignationApi';
import { emmemployeeApi } from '../services/emmemployeeApi';
import { emeattendanceApi } from '../services/emeattendanceApi';
import { emepayrollApi } from '../services/emepayrollApi';
import { emewagesApi } from '../services/emewagesApi';
import { emreportsApi } from '../services/emreportsApi';
import { fosettingApi } from '../services/foasettingApi';
import { foeaddrbookApi } from '../services/foeaddrbookApi';
import { foecheckinApi } from '../services/foecheckinApi';
import { foecheckinadvApi } from '../services/foecheckinadvApi';
import { foecheckoutApi } from '../services/foecheckoutApi';
import { foehousekeepingApi } from '../services/foehousekeepingApi';
import { foelinkroomsApi } from '../services/foelinkroomsApi';
import { foepaxalterApi } from '../services/foepaxalterApi';
import { foepostingsApi } from '../services/foepostingsApi';
import { foereseradvApi } from '../services/foereseradvApi';
import { foeresercancelApi } from '../services/foeresercancelApi';
import { foereservationApi } from '../services/foereservationApi';
import { foeroomshiftApi } from '../services/foeroomshiftApi';
import { fomheadApi } from '../services/fomheadApi';
import { fomidtypeApi } from '../services/fomidtypeApi';
import { fomplansApi } from '../services/fomplansApi';
import { fompurposeApi } from '../services/fompurposeApi';
import { fomsourceApi } from '../services/fomsourceApi';
import { foreportsApi } from '../services/foreportsApi';
import { foroomsApi } from '../services/foroomsApi';
import { foroomtariffApi } from '../services/foroomtariffApi';
import { foroomtypeApi } from '../services/foroomtypeApi';
import { mtejobApi } from '../services/mtejobApi';
import { mtejobassignApi } from '../services/mtejobassignApi';
import { mtejobworkApi } from '../services/mtejobworkApi';
import { mtmitemtypeApi } from '../services/mtmitemtypeApi';
import { mtmjobtypeApi } from '../services/mtmjobtypeApi';
import { poaareaApi } from '../services/poaareaApi';
import { poaoutletApi } from '../services/poaoutletApi';
import { poaprintersApi } from '../services/poaprintersApi';
import { poasettingApi } from '../services/poasettingApi';
import { poebillApi } from '../services/poebillApi';
import { poebillsettleApi } from '../services/poebillsettleApi';
import { poecashcloseApi } from '../services/poecashcloseApi';
import { poeorderApi } from '../services/poeorderApi';
import { poeorderncApi } from '../services/poeorderncApi';
import { poeissueApi } from '../services/poeissueApi';
import { pomgroupApi } from '../services/pomgroupApi';
import { pomkitchenApi } from '../services/pomkitchenApi';
import { pomproductApi } from '../services/pomproductApi';
import { pomproductblockApi } from '../services/pomproductblockApi';
import { pomtableApi } from '../services/pomtableApi';
import { pomtaxApi } from '../services/pomtaxApi';
import { pomreceipeApi } from '../services/pomreceipeApi';
import { poreportsApi } from '../services/poreportsApi';
import { stsettingApi } from '../services/stasettingApi';
import { stegateApi } from '../services/stegateApi';
import { steissueApi } from '../services/steissueApi';
import { stepurchaseApi } from '../services/stepurchaseApi';
import { stmgroupApi } from '../services/stmgroupApi';
import { stmproductApi } from '../services/stmproductApi';
import { stmsupplierApi } from '../services/stmsupplierApi';
import { streportsApi } from '../services/streportsApi';
import { reviewApi } from '../services/greviewApi';

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      switch (action.payload.originalStatus) {
        case 500: {
          notification.error({
            title: 'Server Error',
            message: action.payload.data
          });
          break;
        }
        case 400: {
          notification.error({
            title: 'Error',
            message: action.payload.data
          });
          break;
        }
        case 401: {
          notification.error({
            title: 'Error',
            message: action.payload.data
          });
          console.log('LogOut');
          Logout();
          break;
        }
        case 301: {
          notification.error({
            title: 'Error',
            message: action.payload.data
          });
          break;
        }
        case 2001: {
          notification.success({
            title: 'Sucess',
            message: action.payload.data
          });
          break;
        }
      }
    }

    return next(action);
  };

// Function to load the last state from localStorage
const loadLastState = () => {
  try {
    const serializedState = localStorage.getItem('gdata');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error('Could not load state:', err);
    return undefined;
  }
};

// Load the preloaded state
const preloadedState = loadLastState();

const rootReducer = combineReducers({
  [usersApi.reducerPath]: usersApi.reducer,
  [ausermenuApi.reducerPath]: ausermenuApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [systemsApi.reducerPath]: systemsApi.reducer,
  [countryApi.reducerPath]: countryApi.reducer,
  [stateApi.reducerPath]: stateApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  [amenuApi.reducerPath]: amenuApi.reducer,
  [amodulesApi.reducerPath]: amodulesApi.reducer,
  [modeApi.reducerPath]: modeApi.reducer,
  [taxgroupApi.reducerPath]: taxgroupApi.reducer,
  [taxApi.reducerPath]: taxApi.reducer,
  [uomApi.reducerPath]: uomApi.reducer,
  [smsApi.reducerPath]: smsApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [auditApi.reducerPath]: auditApi.reducer,
  [fosettingApi.reducerPath]: fosettingApi.reducer,
  [foroomtypeApi.reducerPath]: foroomtypeApi.reducer,
  [foroomtariffApi.reducerPath]: foroomtariffApi.reducer,
  [foroomsApi.reducerPath]: foroomsApi.reducer,
  [fomplansApi.reducerPath]: fomplansApi.reducer,
  [fomsourceApi.reducerPath]: fomsourceApi.reducer,
  [fomidtypeApi.reducerPath]: fomidtypeApi.reducer,
  [fompurposeApi.reducerPath]: fompurposeApi.reducer,
  [fomheadApi.reducerPath]: fomheadApi.reducer,
  [foeaddrbookApi.reducerPath]: foeaddrbookApi.reducer,
  [foereservationApi.reducerPath]: foereservationApi.reducer,
  [foereseradvApi.reducerPath]: foereseradvApi.reducer,
  [foeresercancelApi.reducerPath]: foeresercancelApi.reducer,
  [foecheckinApi.reducerPath]: foecheckinApi.reducer,
  [foecheckinadvApi.reducerPath]: foecheckinadvApi.reducer,
  [foepostingsApi.reducerPath]: foepostingsApi.reducer,
  [foecheckoutApi.reducerPath]: foecheckoutApi.reducer,
  [foehousekeepingApi.reducerPath]: foehousekeepingApi.reducer,
  [foeroomshiftApi.reducerPath]: foeroomshiftApi.reducer,
  [foepaxalterApi.reducerPath]: foepaxalterApi.reducer,
  [foelinkroomsApi.reducerPath]: foelinkroomsApi.reducer,
  [foreportsApi.reducerPath]: foreportsApi.reducer,
  [acsettingApi.reducerPath]: acsettingApi.reducer,
  [acmheadApi.reducerPath]: acmheadApi.reducer,
  [acmcompanyApi.reducerPath]: acmcompanyApi.reducer,
  [aceexpensesApi.reducerPath]: aceexpensesApi.reducer,
  [aceincomesApi.reducerPath]: aceincomesApi.reducer,
  [acepaymentApi.reducerPath]: acepaymentApi.reducer,
  [acereceiptApi.reducerPath]: acereceiptApi.reducer,
  [acreportsApi.reducerPath]: acreportsApi.reducer,
  [poaareaApi.reducerPath]: poaareaApi.reducer,
  [poaoutletApi.reducerPath]: poaoutletApi.reducer,
  [poaprintersApi.reducerPath]: poaprintersApi.reducer,
  [poasettingApi.reducerPath]: poasettingApi.reducer,
  [pomtaxApi.reducerPath]: pomtaxApi.reducer,
  [pomgroupApi.reducerPath]: pomgroupApi.reducer,
  [pomkitchenApi.reducerPath]: pomkitchenApi.reducer,
  [pomproductApi.reducerPath]: pomproductApi.reducer,
  [pomproductblockApi.reducerPath]: pomproductblockApi.reducer,
  [pomtableApi.reducerPath]: pomtableApi.reducer,
  [pomreceipeApi.reducerPath]: pomreceipeApi.reducer,
  [poeorderApi.reducerPath]: poeorderApi.reducer,
  [poeorderncApi.reducerPath]: poeorderncApi.reducer,
  [poebillApi.reducerPath]: poebillApi.reducer,
  [poebillsettleApi.reducerPath]: poebillsettleApi.reducer,
  [poecashcloseApi.reducerPath]: poecashcloseApi.reducer,
  [poeissueApi.reducerPath]: poeissueApi.reducer,
  [poreportsApi.reducerPath]: poreportsApi.reducer,
  [bqsettingApi.reducerPath]: bqsettingApi.reducer,
  [bqmhallApi.reducerPath]: bqmhallApi.reducer,
  [bqmfunctionApi.reducerPath]: bqmfunctionApi.reducer,
  [bqmserviceApi.reducerPath]: bqmserviceApi.reducer,
  [bqebillApi.reducerPath]: bqebillApi.reducer,
  [bqebookingApi.reducerPath]: bqebookingApi.reducer,
  [bqreportsApi.reducerPath]: bqreportsApi.reducer,
  [stsettingApi.reducerPath]: stsettingApi.reducer,
  [stmsupplierApi.reducerPath]: stmsupplierApi.reducer,
  [stmgroupApi.reducerPath]: stmgroupApi.reducer,
  [stmproductApi.reducerPath]: stmproductApi.reducer,
  [stegateApi.reducerPath]: stegateApi.reducer,
  [stepurchaseApi.reducerPath]: stepurchaseApi.reducer,
  [streportsApi.reducerPath]: streportsApi.reducer,
  [steissueApi.reducerPath]: steissueApi.reducer,
  [emmdepartmentApi.reducerPath]: emmdepartmentApi.reducer,
  [emmdesignationApi.reducerPath]: emmdesignationApi.reducer,
  [emmemployeeApi.reducerPath]: emmemployeeApi.reducer,
  [emeattendanceApi.reducerPath]: emeattendanceApi.reducer,
  [emepayrollApi.reducerPath]: emepayrollApi.reducer,
  [emewagesApi.reducerPath]: emewagesApi.reducer,
  [emreportsApi.reducerPath]: emreportsApi.reducer,
  [mtmjobtypeApi.reducerPath]: mtmjobtypeApi.reducer,
  [mtmitemtypeApi.reducerPath]: mtmitemtypeApi.reducer,
  [mtejobApi.reducerPath]: mtejobApi.reducer,
  [mtejobassignApi.reducerPath]: mtejobassignApi.reducer,
  [mtejobworkApi.reducerPath]: mtejobworkApi.reducer,
  [reviewApi.reducerPath]: reviewApi.reducer,

  gdata: authSlice.reducer,
  glogic: logicSlice.reducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddiware) =>
    getDefaultMiddiware().concat(
      rtkQueryErrorLogger,
      usersApi.middleware,
      ausermenuApi.middleware,
      companyApi.middleware,
      systemsApi.middleware,
      countryApi.middleware,
      stateApi.middleware,
      paymentApi.middleware,
      amenuApi.middleware,
      amodulesApi.middleware,
      modeApi.middleware,
      taxgroupApi.middleware,
      taxApi.middleware,
      uomApi.middleware,
      smsApi.middleware,
      dashboardApi.middleware,
      auditApi.middleware,
      fosettingApi.middleware,
      foroomtypeApi.middleware,
      foroomtariffApi.middleware,
      foroomsApi.middleware,
      fomplansApi.middleware,
      fomsourceApi.middleware,
      fomidtypeApi.middleware,
      fompurposeApi.middleware,
      fomheadApi.middleware,
      foeaddrbookApi.middleware,
      foereservationApi.middleware,
      foereseradvApi.middleware,
      foeresercancelApi.middleware,
      foecheckinApi.middleware,
      foecheckinadvApi.middleware,
      foepostingsApi.middleware,
      foecheckoutApi.middleware,
      foehousekeepingApi.middleware,
      foeroomshiftApi.middleware,
      foepaxalterApi.middleware,
      foelinkroomsApi.middleware,
      foreportsApi.middleware,
      acsettingApi.middleware,
      acmheadApi.middleware,
      acmcompanyApi.middleware,
      aceexpensesApi.middleware,
      aceincomesApi.middleware,
      acepaymentApi.middleware,
      acereceiptApi.middleware,
      acreportsApi.middleware,
      poaareaApi.middleware,
      poaoutletApi.middleware,
      poaprintersApi.middleware,
      poasettingApi.middleware,
      pomtaxApi.middleware,
      pomgroupApi.middleware,
      pomkitchenApi.middleware,
      pomproductApi.middleware,
      pomproductblockApi.middleware,
      pomtableApi.middleware,
      pomreceipeApi.middleware,
      poeorderApi.middleware,
      poeorderncApi.middleware,
      poebillApi.middleware,
      poebillsettleApi.middleware,
      poecashcloseApi.middleware,
      poeissueApi.middleware,
      poreportsApi.middleware,
      bqsettingApi.middleware,
      bqmhallApi.middleware,
      bqmfunctionApi.middleware,
      bqmserviceApi.middleware,
      bqebillApi.middleware,
      bqebookingApi.middleware,
      bqreportsApi.middleware,
      stsettingApi.middleware,
      stmsupplierApi.middleware,
      stmgroupApi.middleware,
      stmproductApi.middleware,
      stegateApi.middleware,
      stepurchaseApi.middleware,
      streportsApi.middleware,
      steissueApi.middleware,
      emmdepartmentApi.middleware,
      emmdesignationApi.middleware,
      emmemployeeApi.middleware,
      emeattendanceApi.middleware,
      emepayrollApi.middleware,
      emewagesApi.middleware,
      emreportsApi.middleware,
      mtmjobtypeApi.middleware,
      mtmitemtypeApi.middleware,
      mtejobApi.middleware,
      mtejobassignApi.middleware,
      mtejobworkApi.middleware,
      reviewApi.middleware
    )
  //preloadedState
});

// store.subscribe(() => {
//   try {
//     const state = store.getState();
//     const serializedState = JSON.stringify(state);
//     localStorage.setItem('gdata', serializedState);
//   } catch (err) {
//     console.error('Could not save state:', err);
//   }
// });

export default store;

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
