import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import moment from 'moment-timezone';
import qz from 'qz-tray';
import React, { useState } from 'react';
import { RootState } from '../redux/store';
import { v4 as uuidv4 } from 'uuid';

export const apiLink1 =
  window.location.hostname === 'localhost'
    ? 'http://localhost:8080/api/'
    : 'https://bhms.azurewebsites.net/api/';

export const apiLink =
  window.location.hostname === 'localhost'
    ? 'http://localhost:8080/api/'
    : 'https://api.bluehms.com/api/';

export const apiLink2 = 'https://hms-api-nine.vercel.app/api/'; //'http://192.168.206.41:8080/api/';

const GlobalContext = React.createContext({});
export const GlobalProvider = GlobalContext.Provider;
export default GlobalContext;

//export const logoPath = 'https://bluetechstore.blob.core.windows.net/bluehms/logo/';
export const logoPath =
  'https://bluetechstore.blob.core.windows.net/bluehms/logo/';

export const btBaseQuery = fetchBaseQuery({
  baseUrl: apiLink,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).gdata.token;
    const syskey = (retrieveSetting('syskey') ?? uuidv4()) || 'new';
    storeSetting('syskey', syskey);

    if (token) {
      headers.set('auth', `${token}`);
    } else {
      headers.set('syskey', `${syskey}`);
    }
    return headers;
  }
});

export const storeToken = (jwtToken: string) => {
  try {
    localStorage.setItem('token', jwtToken);
  } catch (error) {
    // Error saving data
  }
};

export const retrieveToken = () => {
  try {
    const value = localStorage.getItem('token');
    if (value !== null) {
      return value;
    }
  } catch (error) {
    return error;
  }
};

export const storeSetting = (name: string, value: string) => {
  try {
    localStorage.setItem(name, value);
  } catch (error) {
    // Error saving data
  }
};

export const retrieveSetting = (name: string) => {
  try {
    const value = localStorage.getItem(name);
    if (value !== null) {
      return value;
    }
  } catch (error) {
    return error;
  }
};

export const removeSetting = (name: string) => {
  try {
    localStorage.removeItem(name);
    return true;
  } catch (error) {
    return error;
  }
};

export const modeType = [
  { id: '0', label: 'Credit' },
  { id: '1', label: 'Cash' },
  { id: '2', label: 'Card' },
  { id: '3', label: 'Cheque' },
  { id: '4', label: 'Bank Transfer' },
  { id: '5', label: 'UPI' },
  { id: '6', label: 'Complimentary' },
  { id: '7', label: 'Void' }
];

export const useAsyncState = (initialState: any) => {
  const [state, setState] = useState(initialState);

  const asyncSetState = (value: any) => {
    return new Promise((resolve) => {
      setState(value);
      setState((current: any) => {
        resolve(current);
        return current;
      });
    });
  };

  return [state, asyncSetState];
};

export const toUTCDate = (locdt: any) => {
  var localTime = moment(locdt).utc().format('YYYY-MM-DD HH:MM:SS');

  return localTime;
};

var a = [
  '',
  'one ',
  'two ',
  'three ',
  'four ',
  'five ',
  'six ',
  'seven ',
  'eight ',
  'nine ',
  'ten ',
  'eleven ',
  'twelve ',
  'thirteen ',
  'fourteen ',
  'fifteen ',
  'sixteen ',
  'seventeen ',
  'eighteen ',
  'nineteen '
];
var b = [
  '',
  '',
  'twenty',
  'thirty',
  'forty',
  'fifty',
  'sixty',
  'seventy',
  'eighty',
  'ninety'
];

export const inWords = (num) => {
  if ((num = num.toString()).length > 9) return 'overflow';
  let n: any = ('000000000' + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = '';
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore '
      : '';
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh '
      : '';
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand '
      : '';
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred '
      : '';
  str +=
    n[5] != 0
      ? (str != '' ? 'and ' : '') +
        (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) +
        'only '
      : 'only';
  return str.toUpperCase();
};

// qz tray start here
export const Print = async (printer: string, htmlText: string) => {
  try {
    qz.security.setCertificatePromise((resolve, reject) => {
      resolve(
        '-----BEGIN PRIVATE KEY-----\n' +
          'MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDE0fEXmYWS5/qT\n' +
          '+RJzOsrzIT1/JPu1eBPhcX6nZfDIC0tCDQfDvAl5jXqz9NIw7Ge3AXpENpzlnMrW\n' +
          'C7POzdx/2E1ShxLJPRygFr72ZkgdEghdcuaN1RF++49syc+OIipItvJskKsiWqio\n' +
          '50h/Z0RH3WctHTcytCzVup05t5KLlmPa79OyLdLE7zUS/SO3ISWa5buxJMimHGCm\n' +
          'OnlFan5XcCrOgQvSwOoxlr5whHZqDowk1/o6V4s6Rw5nY42hFTPShFmv63N+t5Eb\n' +
          '2Svrjv6eiP73Z36qM6jvH8YsbrATMdjaskL9NVBK0u/H6U2MTiOkDsMOIdEgo8Ru\n' +
          'c/PAlKWvAgMBAAECggEAUr81Dkf0GXRALC/+KsXgB2HFOgwxavTl3tVZe0VneqMM\n' +
          '6+MQjaEjlp/9o2TdbcYmQJF1dvJC+mH2ARGAcgSonHMy5ux04Hg57bBCbtNHpDkt\n' +
          'nSQufBjFN07J2OHrygThJBsORsh3uoTE/eXm2/Ftio0x0IzE5SNM6jp/2YEtMlO2\n' +
          'NHtqmyyR5gpiLat2U1NSSwaSoIulPR3znRXzlYQJj2tLADgcVUWGm7c5rG5gIcWl\n' +
          'X//GKXLmscXFvUjpgY+ljUWLsycahcFtUqudtj35pqbNF4JDu8/74fKRr8mQIw2B\n' +
          'icR5tKWZI+a/00/0j6r045kcI1mbOHX83G0orDEz8QKBgQDb0PUNbXdqEeXK2yLI\n' +
          '1wf7avZExXfCmhdObsUEQfTtNbANJRHok3qha9yZAPEGehC66EFdEBfkgw1tdz4F\n' +
          'ftE4ZN+sQmqNljXFO8xd8jpv9tC77QVbtrMXY+/RxdleAE1RRqoqzpCdVRp/IKoH\n' +
          '0M6QyCbLuF1cMJo8V3Eytv8y6QKBgQDlN+2SJg8tIL6U6SHQQx4jnXrOwiRJilKI\n' +
          'xZSwFSAT+bAv1wZiPONVksj9bAH1v2tSZY+ifwCScgUg85QLBg5clkNvqapuxMhq\n' +
          '/r+oBVGfSjMmQ0w+htXMpUNzdFA2pljb6WrfulL+jrzuI7RhMP1mJQl7XiaCuaKq\n' +
          'eQOUfgNE1wKBgCNU9O6Rl84ezlbK3k518byBts/bBaLr7a8Xsuc6SsoOBRw3xycg\n' +
          '/WcQ1UZqllM6L5I7XrY8rfp27iOYNQnhFnQAEsu3aTUU3dO0Buda1fXFMEvkjyXs\n' +
          'ueCamaOYL5YiaksUFqoGxcvkL/wbHflfoLQ417OLEMaOsVvdmj5Sl7ZxAoGBAJX7\n' +
          'TGWupYt/u3QwP0HISPZA2tRiz9+WtH5t2eaFTCIF5LTI25J4CMA7dWvsujOUdqLJ\n' +
          'dXx74WHxXU28U4gLBGaso59ywuQLup/X5IwKPugdYu6MZl0bWNW45TOsTbgUjFwI\n' +
          'lTei8R9DQngHwMU/iu7FGTkx17z9vmQRHriQ446tAoGAb+oE2kwf6LXybl8PJPjN\n' +
          'n9GhcU8ejm55A0CJtDHdaXMWVlos+84oxiHEnEA5Q6tEtAlr4D78AxdTP6thUFly\n' +
          'OsfhLdyma35mD7b8MtYMjIoSyw4/YamT+9YFkSH/FK8OqmxrfOxfZIRnUco5B6/3\n' +
          'd+Ikff7cMILJJhiNCAOh6jE=\n' +
          '-----END PRIVATE KEY-----'
      );
    });

    if (!qz.websocket.isActive()) {
      try {
        await qz.websocket.connect({
          host: ['localhost'],
          port: {
            secure: [8181],
            insecure: [8182]
          },
          retries: 0,
          keepAlive: 60,
          delay: 0
        });

        let config = qz.configs.create(printer);
        await qz.print(config, [
          {
            type: 'pixel',
            format: 'html',
            flavor: 'plain',
            data: htmlText
          }
        ]);

        return true;
      } catch (error) {
        console.log(error);
        var myWindow = window.open('', 'BlueHMS-Print');
        myWindow.document.write('<html><head><title>BlueHMS-Print</title>');
        myWindow.document.write(
          "<style type='text/css'> *, html {margin:0;padding:0;} </style>"
        );
        myWindow.document.write('</head><body>');
        myWindow.document.write(htmlText);
        myWindow.document.write('</body></html>');
        myWindow.document.close(); // necessary for IE >= 10
        let myDelay = setInterval(checkReadyState, 100);

        function checkReadyState() {
          if (myWindow.document.readyState === 'complete') {
            clearInterval(myDelay);
            myWindow.focus(); // necessary for IE >= 10

            myWindow.print();
            myWindow.close();
          }
        }
        return false;
      }
    } else {
      let config = qz.configs.create(printer);
      await qz.print(config, [
        {
          type: 'pixel',
          format: 'html',
          flavor: 'plain',
          data: htmlText
        }
      ]);

      return true;
    }
  } catch (e) {
    var myWindow1 = window.open('', 'BlueHMS-Print');
    myWindow1.document.write('<html><head><title>BlueHMS-Print</title>');
    myWindow1.document.write(
      "<style type='text/css'> *, html {margin:0;padding:0;} </style>"
    );
    myWindow1.document.write('</head><body>');
    myWindow1.document.write(htmlText);
    myWindow1.document.write('</body></html>');
    myWindow1.document.close(); // necessary for IE >= 10
    let myDelay = setInterval(checkReadyState, 100);

    function checkReadyState() {
      if (myWindow1.document.readyState === 'complete') {
        clearInterval(myDelay);
        myWindow1.focus(); // necessary for IE >= 10

        myWindow1.print();
        myWindow1.close();
      }
    }
    return false;
  }
};

export const formattedDate = (dt: Date) => {
  return moment(dt).isValid()
    ? moment(dt).format('YYYY-MM-DD HH:mm:ss')
    : moment().format('YYYY-MM-DD HH:mm:ss');
};
