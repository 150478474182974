import { Box } from '@mui/material';
import BTLogo, { BlueHMSLogo, BluetechLogo, BTLogo1 } from 'src/assets';

function Logo1() {
  return (
    <Box sx={{ margin: 10 }}>
      <BTLogo />
    </Box>
  );
}

export function Logo() {
  return (
    <Box sx={{ height: 100 }}>
      <BTLogo1 />
    </Box>
  );
}

export function BlueTechLogoImg() {
  return (
    <Box sx={{ height: 100 }}>
      <BluetechLogo />
    </Box>
  );
}

export function BlueHMSLogoImg() {
  return (
    <Box sx={{ margin: 2 }}>
      <BlueHMSLogo />
    </Box>
  );
}

export default Logo1;
