import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Typography,
  Link,
  useTheme,
  styled
} from '@mui/material';
import PropTypes from 'prop-types';
import { FC, ReactNode, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';

interface GuestLayoutProps {
  children?: ReactNode;
}

const AuthWrapper = styled(Box)(
  () => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    align-items: center;
`
);

function Copyright(props: any) {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = ''; // Chrome requires returnValue to be set
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="http://bluetechsoftwares.com/">
        BLUETECH
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const GuestLayout: FC<GuestLayoutProps> = ({ children }) => {
  const theme = useTheme();

  return (
    <>
      <CssBaseline />
      <Grid container component="main">
        <AuthWrapper>
          <Helmet>
            <title>BlueTech Hotel Management</title>
          </Helmet>
          <Container maxWidth="lg">{children || <Outlet />}</Container>
        </AuthWrapper>
      </Grid>

      {/* Footer */}
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          borderTop: `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6]
        }}
      >
        <Copyright sx={{ mt: 5 }} />
      </Container>
      {/* End footer */}
    </>
  );
};

GuestLayout.propTypes = {
  children: PropTypes.node
};

export default GuestLayout;
