import {
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  GlobalStyles,
  Grid,
  Toolbar,
  Typography,
  styled
} from '@mui/material';
import Link from '@mui/material/Link';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';
import { BlueTechLogoImg } from 'src/components/Logo1';

interface BaseLayoutProps {
  children?: ReactNode;
}

const AuthWrapper = styled(Box)(
  () => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    align-items: center;
`
);
const footers = [
  {
    title: 'Company',
    description: [
      { title: 'About us', link: '#/home/aboutus' },
      { title: 'Pricing', link: '#/home/contactus' },
      { title: 'Contact us', link: '#/home/pricing' }
    ]
  },
  {
    title: 'Legal',
    description: [
      { title: 'Privacy policy', link: '#/home/paymentprivacy' },
      { title: 'Terms and Conditions', link: '#/home/terms' },
      { title: 'Cancellation Policy', link: '#/home/policy' }
    ]
  },
  {
    title: 'Legal',
    description: [
      { title: 'Disclaimer', link: '#/home/disclaimer' },
      { title: 'Intellectual Property', link: '#/home/intellectual' },
      { title: 'Process flow', link: '#/home/processflow' }
    ]
  }
];

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="http://bluetechsoftwares.com/">
        BLUETECH
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyles
          styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }}
        />
        <CssBaseline />
        <AppBar
          position="static"
          color="default"
          elevation={0}
          sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        >
          <Toolbar sx={{ flexWrap: 'wrap' }}>
            <Typography
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <Grid item xs={12}>
                <Box display="flex" justifyContent="left" alignItems="left">
                  <BlueTechLogoImg />
                </Box>
              </Grid>
            </Typography>
            <nav>
              <Link
                variant="button"
                color="text.primary"
                href="#/home/"
                sx={{ my: 1, mx: 1.5 }}
              >
                Home
              </Link>
              <Link
                variant="button"
                color="text.primary"
                href="#/home/aboutus"
                sx={{ my: 1, mx: 1.5 }}
              >
                About us
              </Link>
              <Link
                variant="button"
                color="text.primary"
                href="#/home/contactus"
                sx={{ my: 1, mx: 1.5 }}
              >
                Contact us
              </Link>
            </nav>
            <Button href="#" variant="outlined" sx={{ my: 1, mx: 1.5 }}>
              Login
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container component="main">
          <AuthWrapper>
            <Helmet>
              <title>BlueTech Hotel Management</title>
            </Helmet>
            <Container maxWidth="lg">{children || <Outlet />}</Container>
          </AuthWrapper>
        </Grid>
      </ThemeProvider>
      {/* Footer */}
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6]
        }}
      >
        <Grid container spacing={4} justifyContent="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item) => (
                  <li key={item.title}>
                    <Link
                      href={item.link}
                      variant="subtitle1"
                      color="text.secondary"
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Copyright sx={{ mt: 5 }} />
      </Container>
      {/* End footer */}
    </>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node
};

export default BaseLayout;
